import React from "react";

const Header = ({ theme }) => {
  return (
    <>
      {theme === "light" && (
        <style
          dangerouslySetInnerHTML={{
            __html: `.navbar,
      NAV.bg-light {
        background: #cee5ea !important;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
      .navbar-nav LI {
        border-bottom: 3px solid #cee5ea;
      }
      .navbar-nav LI a {
        color: #212529 !important;
      }
      .navbar-light .navbar-toggler {
        border-color: #212529 !important;
      }
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(34,37,41, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
      }
      .nav-item.active, .nav-item:hover {
        font-weight: 500;
        border-bottom: 2px solid #212529;
      }`,
          }}
        />
      )}
      <nav className="navbar navbar-expand-lg sticky-top navbar-light bg-light">
        <div className="container">
          <a
            className="navbar-brand"
            href="https://www.taskanalytics.com/"
            title="Task Analytics - Measure your digital customer experience"
          >
            <img
              src={
                theme === "light"
                  ? "/assets/img/task-analytics_logo.svg"
                  : "/assets/img/task-analytics_white.png"
              }
              alt="Task Analytics logo"
            />
          </a>
          <br />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#taskanalytics_menu"
            aria-controls="taskanalytics_menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="navbar-collapse collapse float-right"
            id="taskanalytics_menu"
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/product"
                  title="How Task Analytics works"
                >
                  product
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/features"
                  title="Features of Task Analytics"
                >
                  features
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/integrations"
                  title="Task Analytics Integrations"
                >
                  integrations
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/pricing"
                  title="Task Analytics Pricing"
                >
                  pricing
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/clients"
                  title="Clients &amp; use cases of Task Analytics"
                >
                  cases
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/partners"
                  title="Partnering with Task Analytics"
                >
                  partners
                </a>
              </li>

              <li className="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none menu_login">
                <a
                  className="menu_login"
                  href="https://admin2.taskanalytics.com/"
                  rel="noopener noreferrer"
                  title="Log in"
                  target="_blank"
                >
                  log in
                </a>
                <a
                  className="menu_login_alt"
                  href="https://www.taskanalytics.com/request-demo"
                  rel="noopener noreferrer"
                  title="Request a demo"
                  target="_blank"
                >
                  Request a demo
                </a>
              </li>
            </ul>
          </div>
          <div className="mt-0 mt-md-0 d-none d-lg-block float-right">
            <a
              className="btn btn-outline-secundary"
              href="https://www.taskanalytics.com/request-demo"
              rel="noopener noreferrer"
              target="_blank"
              title="Request a demo"
            >
              Request a demo
            </a>
            <a
              className="btn btn-outline-primary internal_link"
              href="https://admin2.taskanalytics.com/"
              rel="noopener noreferrer"
              target="_blank"
              title="Existing customer? Log in"
            >
              Log in
            </a>
          </div>
        </div>
      </nav>
      {/* ///////////////////////////// CONTENT HEADER ////////////////*/}

      {/* header */}
      <div className="callout" style={{ display: "none" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6 text-lg-left text-md-center">
              <img
                src="/assets/icons/comment-square.svg"
                alt="icon name"
                className="icon"
              />
              Need help?
              <a href="/contact" title="Contact us">
                <span>Contact Us</span>
              </a>
            </div>
            <div className="col d-none d-lg-block text-right">
              <a href="/request-demo" title="Request a demo">
                <span>Request demo</span>
              </a>
              &nbsp; - &nbsp;
              <a
                href="https://admin2.taskanalytics.com"
                rel="noopener noreferrer"
                title="Sign up for a free trial!"
                target="_blank"
              >
                <span>Sign up</span> for a free trial!
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
