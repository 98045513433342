import React from "react";

const Footer = () => {
  return (
    <footer className="pt-4">
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-10 mx-auto col-xs-12 col-sm-12 col-md-4 footer-logo">
              <a
                className="navbar-brand"
                href="https://www.taskanalytics.com/"
                title="Task Analytics - Measure your digital customer experience"
              >
                <img
                  src="/assets/img/task-analytics.png"
                  alt="Task Analytics logo"
                />
              </a>
              <br />
              <span className="footer-slogan">
                Now you know <b>why</b>
              </span>
              <br />
              <a href="/gdpr" title="Task Analytics is GDPR compliant!">
                <img
                  src="/assets/img/task-analytics_gdpr-compliancy.png"
                  className="gdpr"
                  alt="Task Analytics is GDPR compliant"
                />
              </a>
            </div>
            <div className="col-4 mx-auto col-sm-6 col-md-4">
              <b>Company</b>
              <ul>
                <li>
                  <a href="/about-us" title="About Task Analytics">
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="/clients"
                    title="Clients &amp; use cases of Task Analytics"
                  >
                    Clients
                  </a>
                </li>
                <li>
                  <a href="/team" title="Meet the team of Task Analytics">
                    Team
                  </a>
                </li>
                <li>
                  <a href="/careers" title="Careers at Task Analytics">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="/contact" title="Contact Task Analytics">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="/newsletter/subscribe"
                    title="Sign up for the newsletter of Task Analytics"
                  >
                    Newsletter
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-4 mx-auto col-sm-6 col-md-4">
              <b>Resources</b>
              <ul>
                {/* <li><a href="https://www.taskanalytics.com/customers" title="">Download</a></li>*/}
                <li>
                  <a href="/blog" title="Task Analytics Blog">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="/integrations" title="Task Analytics Integrations">
                    integrations
                  </a>
                </li>
                <li>
                  <a href="/pricing" title="Task Analytics Pricing">
                    pricing
                  </a>
                </li>
                <li>
                  <a
                    href="https://help.taskanalytics.com/"
                    rel="noopener noreferrer"
                    title="Task Analytics Help Center"
                    target="_blank"
                  >
                    Help Center
                  </a>
                </li>
                {/* <li><a href="https://www.taskanalytics.com/blog" title="">Blog</a></li>*/}
                <li>
                  <a href="/portal/" title="Task Analytics Agency Portal">
                    Agency Portal
                  </a>
                </li>
                <li>
                  <a href="/gdpr" title="Task Analytics is GDPR compliant">
                    GDPR
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-6 footer-links col-md-auto">
              <a
                href="/privacy"
                title="Privacy statement of Task Analytics"
                className="text-nowrap"
              >
                Privacy
              </a>
              <a
                href="/terms-of-service"
                title="Task Analytics' Terms of Service"
                className="text-nowrap"
              >
                Terms of Service
              </a>
              <a
                href="/contact"
                title="Contact Task Analytics for more information"
                className="text-nowrap"
              >
                Contact Us
              </a>
            </div>
            <div className="col-12 col-md-6 footer-copyright col-md-auto">
              Copyright &copy; 2021.
              <a
                href="https://www.taskanalytics.com/"
                title="Task Analytics - Task Performance Management"
              >
                Task Analytics
              </a>
              . All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
