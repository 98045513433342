import React from "react";
import Header from "./header";
import Footer from "./footer";
import Helmet from "react-helmet";

const logo =
  "https://www.taskanalytics.com/assets/img/favicon/ms-icon-310x310.png";

const Layout = ({ children, theme, metaInfo }) => {
  return (
    <>
      <Helmet>
        <title>{metaInfo?.title ?? "Task Analytics"}</title>
        <meta
          property="og:title"
          content={metaInfo?.someTitle || metaInfo?.title || "Task Analytics"}
        />
        <meta
          name="twitter:title"
          content={metaInfo?.someTitle || metaInfo?.title || "Task Analytics"}
        />

        <meta
          name="description"
          content={
            metaInfo?.description ??
            "Measure customer intent. Gain actionable insight with analytics that focus on user tasks, not clicks."
          }
        />
        <meta
          property="og:description"
          content={
            metaInfo?.description ??
            "Measure customer intent. Gain actionable insight with analytics that focus on user tasks, not clicks."
          }
        />
        <meta
          name="twitter:description"
          content={
            metaInfo?.description ??
            "Measure customer intent. Gain actionable insight with analytics that focus on user tasks, not clicks."
          }
        />

        <meta
          property="og:image"
          content={metaInfo?.image?.asset?.url ?? logo}
        />
        <meta property="og:image:alt" content={metaInfo?.imageAlt ?? ""} />
        <meta
          name="twitter:image"
          content={metaInfo?.image?.asset?.url ?? logo}
        />
        <meta name="twitter:image:alt" content={metaInfo?.imageAlt ?? ""} />

        <meta property="og:url" content="https://www.taskanalytics.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:fb:page_id" content="taskanalytics" />
        <meta name="twitter:site" content="@taskanalytics" />
        <meta
          name="twitter:card"
          content={metaInfo?.image ? "summary_large_image" : "summary"}
        />
      </Helmet>
      <Header theme={theme} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
